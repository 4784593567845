import { selectEnabledFeatureFlags } from 'selectors/enabledFeatureFlags';
import { selectMSTeamsContext } from 'selectors/msTeamsSelector';
// eslint-disable-next-line import/no-cycle
import store from 'store.js';

import { FEATURE_FLAGS } from 'constants/ProjectConstants';

const isMsTeamsVisitor = () => selectMSTeamsContext(store.getState());

const isFeatureFlagActive = (flagKey) => FEATURE_FLAGS[flagKey] && FEATURE_FLAGS[flagKey].isActive;

const isFeatureFlagActiveNewKeyFormat = (flagObj) => flagObj && flagObj.isActive;

const checkLevelUpFeatureFlagEnabled = () => {
  if (FEATURE_FLAGS.LEVEL_UP.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.LEVEL_UP.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.LEVEL_UP.key)) return isFeatureFlagActive(FEATURE_FLAGS.LEVEL_UP.key);

  return false;
};

const checkSampleEngagementTemplatesEnabled = () => {
  if (FEATURE_FLAGS.SAMPLE_ENGAGEMENT_TEMPLATE.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.SAMPLE_ENGAGEMENT_TEMPLATE.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.SAMPLE_ENGAGEMENT_TEMPLATE.key))
    return isFeatureFlagActive(FEATURE_FLAGS.SAMPLE_ENGAGEMENT_TEMPLATE.key);

  return false;
};

const checkLevelUpPageFeatureFlagEnabled = () => {
  if (FEATURE_FLAGS.LEVEL_UP_PAGE.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.LEVEL_UP_PAGE.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.LEVEL_UP_PAGE.key))
    return isFeatureFlagActive(FEATURE_FLAGS.LEVEL_UP_PAGE.key);

  return false;
};

const checkMeshRecommendationsEnabled = () => {
  if (FEATURE_FLAGS.MESH_RECOMMENDATION.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.MESH_RECOMMENDATION.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.MESH_RECOMMENDATION.key))
    return isFeatureFlagActive(FEATURE_FLAGS.MESH_RECOMMENDATION.key);

  return false;
};

const checkOKRViewIsEnabled = () => {
  if (FEATURE_FLAGS.OKR_VIEW.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.OKR_VIEW.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.OKR_VIEW.key)) return isFeatureFlagActive(FEATURE_FLAGS.OKR_VIEW.key);

  return false;
};

const checkRabbitGoalsTasksFeatureFlagEnabled = () => {
  // shortcircuiting in development

  return true;

  // eslint-disable-next-line no-unreachable
  if (FEATURE_FLAGS.RABBIT_GOALS_TASKS.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.RABBIT_GOALS_TASKS.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RABBIT_GOALS_TASKS.key))
    return isFeatureFlagActive(FEATURE_FLAGS.RABBIT_GOALS_TASKS.key);

  return false;
};

const checkCustomOkrEnabled = () => {
  if (FEATURE_FLAGS.OKR_CUSTOM_NAME_ENABLED.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.OKR_CUSTOM_NAME_ENABLED.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.OKR_CUSTOM_NAME_ENABLED.key))
    return isFeatureFlagActive(FEATURE_FLAGS.OKR_CUSTOM_NAME_ENABLED.key);

  return false;
};

const checkPersonaInsightsEnabled = () => {
  if (FEATURE_FLAGS.ENABLE_PERSONA_INSIGHTS.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ENABLE_PERSONA_INSIGHTS.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_PERSONA_INSIGHTS.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_PERSONA_INSIGHTS.key);

  return false;
};

const checkAIReviewCommentsEnabled = () => {
  if (FEATURE_FLAGS.ENABLE_AI_REVIEW_COMMENTS.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.ENABLE_AI_REVIEW_COMMENTS.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_AI_REVIEW_COMMENTS.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_AI_REVIEW_COMMENTS.key);

  return false;
};

const checkAIReviewManagerSummaryEnabled = () => {
  // return false;
  if (FEATURE_FLAGS.ENABLE_AI_REVIEW_MANAGER_SUMMARY.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.ENABLE_AI_REVIEW_MANAGER_SUMMARY.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_AI_REVIEW_MANAGER_SUMMARY.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_AI_REVIEW_MANAGER_SUMMARY.key);

  return false;
};

const check1on1V2Enabled = () => {
  if (FEATURE_FLAGS.ONE_ON_ONE_V2.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ONE_ON_ONE_V2.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ONE_ON_ONE_V2.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ONE_ON_ONE_V2.key);

  return false;
};

const checkViewsEnabled = () => {
  if (FEATURE_FLAGS.ENABLE_VIEWS.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ENABLE_VIEWS.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_VIEWS.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_VIEWS.key);

  return false;
};

const check1on1V2EventAgendaEnabled = () => {
  if (FEATURE_FLAGS.ONE_ON_ONE_V2_EVENT_AGENDA.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.ONE_ON_ONE_V2_EVENT_AGENDA.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ONE_ON_ONE_V2_EVENT_AGENDA.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ONE_ON_ONE_V2_EVENT_AGENDA.key);

  return false;
};

const checkGraphAccessEnabled = () => {
  /* short-circuiting to enable in development */

  if (FEATURE_FLAGS.GOAL_INSIGHT_GRAPH.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.GOAL_INSIGHT_GRAPH.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.GOAL_INSIGHT_GRAPH.key))
    return isFeatureFlagActive(FEATURE_FLAGS.GOAL_INSIGHT_GRAPH.key);

  return false;
};

const checkGoalGenAIEnabled = () => {
  /* short-circuiting to enable in development */

  if (FEATURE_FLAGS.GOAL_GEN_AI.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.GOAL_GEN_AI.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState());

  if (enabledFeatures.includes(FEATURE_FLAGS.GOAL_GEN_AI.key))
    return isFeatureFlagActive(FEATURE_FLAGS.GOAL_GEN_AI.key);

  return false;
};

const checkMergeIntegrationEnabled = () => {
  if (FEATURE_FLAGS.MERGE_INTEGRATION.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.MERGE_INTEGRATION.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState());

  if (enabledFeatures.includes(FEATURE_FLAGS.MERGE_INTEGRATION.key))
    return isFeatureFlagActive(FEATURE_FLAGS.MERGE_INTEGRATION.key);

  return false;
};

const check1on1TranscriptionEnabled = () => {
  if (FEATURE_FLAGS.ONE_ON_ONE_TRANSCRIPTION.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ONE_ON_ONE_TRANSCRIPTION.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ONE_ON_ONE_TRANSCRIPTION.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ONE_ON_ONE_TRANSCRIPTION.key);

  return false;
};

const checkGoalConfigTemplateEnabled = () => {
  // FOR FASTER DEVELOPMENT
  if (FEATURE_FLAGS.ENABLE_GOAL_CONFIG_TEMPLATES.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.ENABLE_GOAL_CONFIG_TEMPLATES.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_GOAL_CONFIG_TEMPLATES.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_GOAL_CONFIG_TEMPLATES.key);

  return false;
};

const checkAccessControlEnabled = () => {
  if (FEATURE_FLAGS.ENABLE_ACCESS_CONTROL.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ENABLE_ACCESS_CONTROL.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_ACCESS_CONTROL.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_ACCESS_CONTROL.key);

  return false;
};

const checkPartialPublishEnabled = () => {
  // return true;

  if (FEATURE_FLAGS.ENABLE_PARTIAL_PUBLISH.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ENABLE_PARTIAL_PUBLISH.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_PARTIAL_PUBLISH.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_PARTIAL_PUBLISH.key);

  return false;
};

const checkReviewAnalyticsEnabled = () => {
  if (FEATURE_FLAGS.ENABLE_REVIEW_ANALYTICS.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ENABLE_REVIEW_ANALYTICS.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_REVIEW_ANALYTICS.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_REVIEW_ANALYTICS.key);

  return false;
};

const checkOneOnOneMLEnabled = () => {
  if (FEATURE_FLAGS.ONE_ON_ONE_V2_ML.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ONE_ON_ONE_V2_ML.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ONE_ON_ONE_V2_ML.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ONE_ON_ONE_V2_ML.key);

  return false;
};

const checkSankeyChartEnabled = () => {
  if (FEATURE_FLAGS.REVIEW_SANKEY_GRAPH.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.REVIEW_SANKEY_GRAPH.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.REVIEW_SANKEY_GRAPH.key))
    return isFeatureFlagActive(FEATURE_FLAGS.REVIEW_SANKEY_GRAPH.key);

  return false;
};

const checkCFGenAIEnabled = () => {
  // return true;

  if (FEATURE_FLAGS.CF_GEN_AI.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.CF_GEN_AI.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.CF_GEN_AI.key)) return isFeatureFlagActive(FEATURE_FLAGS.CF_GEN_AI.key);

  return false;
};

// In checkNewNavEnabled override the NEW_NAV feature flag to true when the PREVIEW feature flag is true.
// This ensures that functionalities dependent on NEW_NAV work correctly, preventing issues
// with landing pages, redirections, and other checks.
const checkNewNavEnabled = () => {
  if (FEATURE_FLAGS.NEW_NAV.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.NEW_NAV.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (
    enabledFeatures.includes(FEATURE_FLAGS.NEW_NAV.key) ||
    enabledFeatures.includes(FEATURE_FLAGS.NEW_NAV_PREVIEW_FEATURE.key)
  )
    return (
      (isFeatureFlagActive(FEATURE_FLAGS.NEW_NAV.key) ||
        isFeatureFlagActive(FEATURE_FLAGS.NEW_NAV_PREVIEW_FEATURE.key)) &&
      !isMsTeamsVisitor()
    );

  return false;
};

const checkVisierAnalyticsEnabled = () => {
  if (FEATURE_FLAGS.VISIER_ANALYTICS.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.VISIER_ANALYTICS.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.VISIER_ANALYTICS.key) && checkNewNavEnabled())
    return isFeatureFlagActive(FEATURE_FLAGS.VISIER_ANALYTICS.key);

  return false;
};

const checkMeshRecommendsV1Enabled = () => {
  // return true;
  if (FEATURE_FLAGS.MESH_RECOMMENDS_V1.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.MESH_RECOMMENDS_V1.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.MESH_RECOMMENDS_V1.key))
    return isFeatureFlagActive(FEATURE_FLAGS.MESH_RECOMMENDS_V1.key);

  return false;
};

const checkBulkNotifyEnabled = () => {
  // return true;
  if (FEATURE_FLAGS.ENABLE_BULK_NOTIFY.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.ENABLE_BULK_NOTIFY.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.ENABLE_BULK_NOTIFY.key))
    return isFeatureFlagActive(FEATURE_FLAGS.ENABLE_BULK_NOTIFY.key);

  return false;
};

const checkNewCompetencyPanelEnabled = () => {
  // return true;
  if (FEATURE_FLAGS.CF_SCORING_SIDE_PANEL.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.CF_SCORING_SIDE_PANEL.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.CF_SCORING_SIDE_PANEL.key))
    return isFeatureFlagActive(FEATURE_FLAGS.CF_SCORING_SIDE_PANEL.key);

  return false;
};

const checkDenyPublicCFVisibilityEnabled = () => {
  if (FEATURE_FLAGS.DENY_PUBLIC_CF_VISIBILITY.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.DENY_PUBLIC_CF_VISIBILITY.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.DENY_PUBLIC_CF_VISIBILITY.key))
    return isFeatureFlagActive(FEATURE_FLAGS.DENY_PUBLIC_CF_VISIBILITY.key);

  return false;
};

const checkNewStaticDataFeaturesEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_NEW_NAV_STATIC_DATA_FEATURES.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.RELEASE_NEW_NAV_STATIC_DATA_FEATURES.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_NEW_NAV_STATIC_DATA_FEATURES.key))
    // isFeatureFlagActiveNewKeyFormat function is used here for comparison of the new key provided  by launch darkly
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_NEW_NAV_STATIC_DATA_FEATURES);

  return false;
};

const checkCrossModularInsightsEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_CROSS_MODULAR_INSIGHTS.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.RELEASE_CROSS_MODULAR_INSIGHTS.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_CROSS_MODULAR_INSIGHTS.key))
    // isFeatureFlagActiveNewKeyFormat function is used here for comparison of the new key provided  by launch darkly
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_CROSS_MODULAR_INSIGHTS);

  return false;
};

const checkReviewCreationPreviewEnabled = () => {
  if (FEATURE_FLAGS.REVIEW_CREATION_PREVIEW.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.REVIEW_CREATION_PREVIEW.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.REVIEW_CREATION_PREVIEW.key))
    return isFeatureFlagActive(FEATURE_FLAGS.REVIEW_CREATION_PREVIEW.key);

  return false;
};

const checkCollapsibleCardsEnabled = () => {
  if (FEATURE_FLAGS.DEER_COLLAPSIBLE_CARDS.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.DEER_COLLAPSIBLE_CARDS.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.DEER_COLLAPSIBLE_CARDS.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.DEER_COLLAPSIBLE_CARDS);

  return false;
};

const checkSandboxStaticDataFeaturesEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_SANDBOX_STATIC_DATA_FEATURES.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.RELEASE_SANDBOX_STATIC_DATA_FEATURES.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_SANDBOX_STATIC_DATA_FEATURES.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_SANDBOX_STATIC_DATA_FEATURES);

  return false;
};

const checkHomePageNudgesEnabled = () => {
  if (FEATURE_FLAGS.CONFIGURE_HOMEPAGE_NUDGES.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.CONFIGURE_HOMEPAGE_NUDGES.key}. This shouldn't have happened.`
    );
    return true;
  }
  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.CONFIGURE_HOMEPAGE_NUDGES.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.CONFIGURE_HOMEPAGE_NUDGES);

  if (enabledFeatures.includes(FEATURE_FLAGS.CONFIGURE_HOMEPAGE_NUDGES_CAMEL_CASE.key))
    return isFeatureFlagActive(FEATURE_FLAGS.CONFIGURE_HOMEPAGE_NUDGES_CAMEL_CASE.key);

  return false;
};

const checkNewNavPreviewFeatureEnabled = () => {
  if (FEATURE_FLAGS.NEW_NAV_PREVIEW_FEATURE.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.NEW_NAV_PREVIEW_FEATURE.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.NEW_NAV.key)) return false;

  if (enabledFeatures.includes(FEATURE_FLAGS.NEW_NAV_PREVIEW_FEATURE.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.NEW_NAV_PREVIEW_FEATURE);

  return false;
};

const checkDeerSwitcherEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_DEER_SWITCHER.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.RELEASE_DEER_SWITCHER.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_DEER_SWITCHER.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_DEER_SWITCHER);

  return false;
};

const checkIntentLandingPagesFeatureEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_INTENT_LANDING_PAGES.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.RELEASE_INTENT_LANDING_PAGES.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_INTENT_LANDING_PAGES.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_INTENT_LANDING_PAGES);

  return false;
};

const checkVisierReportsEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_VISIER_REPORTS.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.RELEASE_VISIER_REPORTS.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_VISIER_REPORTS.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_VISIER_REPORTS);

  return false;
};

const checkReviewsOverviewBoxGridEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_REVIEWS_OVERVIEW_BOX_GRID.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.RELEASE_REVIEWS_OVERVIEW_BOX_GRID.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_REVIEWS_OVERVIEW_BOX_GRID.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_REVIEWS_OVERVIEW_BOX_GRID);

  return false;
};

const checkBulkCheckInEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_BULK_CHECK_IN.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.RELEASE_BULK_CHECK_IN.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_BULK_CHECK_IN.key))
    return isFeatureFlagActive(FEATURE_FLAGS.RELEASE_BULK_CHECK_IN.key);

  return false;
};

const checkBulkUploadIntentEnabled = () => {
  if (FEATURE_FLAGS.RELEASE_BULK_UPLOAD_INTENT.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.RELEASE_BULK_UPLOAD_INTENT.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];
  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_BULK_UPLOAD_INTENT.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_BULK_UPLOAD_INTENT);
  return false;
};

const checkOverviewExplanationsEnabled = () => {
  if (FEATURE_FLAGS.OVERVIEW_EXPLANATIONS.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.OVERVIEW_EXPLANATIONS.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.OVERVIEW_EXPLANATIONS.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.OVERVIEW_EXPLANATIONS);

  return false;
};

const checkOverviewReviewsExplanationsEnabled = () => {
  if (FEATURE_FLAGS.OVERVIEW_REVIEWS_EXPLANATIONS.isRetired) {
    console.warn(
      `Calling a retired flag ${FEATURE_FLAGS.OVERVIEW_REVIEWS_EXPLANATIONS.key}. This shouldn't have happened.`
    );
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.OVERVIEW_REVIEWS_EXPLANATIONS.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.OVERVIEW_REVIEWS_EXPLANATIONS);

  return false;
};

const checkReleaseOutcomeEnabled = () => {
  // return false;
  if (FEATURE_FLAGS.RELEASE_OUTCOME.isRetired) {
    console.warn(`Calling a retired flag ${FEATURE_FLAGS.RELEASE_OUTCOME.key}. This shouldn't have happened.`);
    return true;
  }

  const enabledFeatures = selectEnabledFeatureFlags(store.getState()) ?? [];

  if (enabledFeatures.includes(FEATURE_FLAGS.RELEASE_OUTCOME.key))
    return isFeatureFlagActiveNewKeyFormat(FEATURE_FLAGS.RELEASE_OUTCOME);

  return false;
};

export {
  checkLevelUpFeatureFlagEnabled,
  checkSampleEngagementTemplatesEnabled,
  checkLevelUpPageFeatureFlagEnabled,
  checkMeshRecommendationsEnabled,
  checkRabbitGoalsTasksFeatureFlagEnabled,
  checkOKRViewIsEnabled,
  checkCustomOkrEnabled,
  checkPersonaInsightsEnabled,
  checkAIReviewCommentsEnabled,
  checkAIReviewManagerSummaryEnabled,
  check1on1V2Enabled,
  checkViewsEnabled,
  check1on1V2EventAgendaEnabled,
  checkGraphAccessEnabled,
  check1on1TranscriptionEnabled,
  checkGoalConfigTemplateEnabled,
  checkAccessControlEnabled,
  checkGoalGenAIEnabled,
  checkMergeIntegrationEnabled,
  checkPartialPublishEnabled,
  checkReviewAnalyticsEnabled,
  checkOneOnOneMLEnabled,
  checkSankeyChartEnabled,
  checkCFGenAIEnabled,
  checkVisierAnalyticsEnabled,
  checkNewNavEnabled,
  checkMeshRecommendsV1Enabled,
  checkBulkNotifyEnabled,
  checkNewCompetencyPanelEnabled,
  checkDenyPublicCFVisibilityEnabled,
  checkNewStaticDataFeaturesEnabled,
  checkReviewCreationPreviewEnabled,
  checkCollapsibleCardsEnabled,
  checkSandboxStaticDataFeaturesEnabled,
  checkHomePageNudgesEnabled,
  checkNewNavPreviewFeatureEnabled,
  checkDeerSwitcherEnabled,
  checkIntentLandingPagesFeatureEnabled,
  checkVisierReportsEnabled,
  checkBulkUploadIntentEnabled,
  checkReviewsOverviewBoxGridEnabled,
  checkCrossModularInsightsEnabled,
  checkBulkCheckInEnabled,
  checkOverviewExplanationsEnabled,
  checkReleaseOutcomeEnabled,
  checkOverviewReviewsExplanationsEnabled,
};
